




<template>
  <div class="container mx-auto">
    <AppBanner class="mb-5 sm:mb-8" />

    <ProjectsGrid />

    <div class="mt-10 sm:mt-20 flex justify-center">
      <router-link
        to="/projects"
        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-sky-500 hover:bg-sky-600 focus:ring-1 focus:ring-sky-900 text-white text-lg sm:text-xl duration-300"
        aria-label="More Projects"
      >
        <Button title="More Projects" />
      </router-link>
    </div>

    <div class="mt-16 sm:mt-24">
      <h2 class="text-2xl sm:text-3xl font-semibold text-center text-gray-800 mb-8">Mini Projects</h2>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <!-- Video 1 -->
        <div class="flex flex-col items-center">
          <div class="w-full h-64 mb-4 overflow-hidden rounded-lg shadow-lg relative" @click="openModal('https://www.portfolio.clicktostock.com/videos/Login_with_garbage.mp4')">
            <video class="w-full h-full object-cover">
              <source src="https://www.portfolio.clicktostock.com/assets/videos/Login_with_garbage.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 hover:bg-opacity-60 transition">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white" viewBox="0 0 24 24" width="50" height="50">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3l14 9-14 9V3z"/>
              </svg>
            </div>
          </div>
          <h3 class="text-xl font-semibold text-gray-700">Login with GARBAGE</h3>
        </div>

        <!-- Video 2 -->
        <div class="flex flex-col items-center">
          <div class="w-full h-64 mb-4 overflow-hidden rounded-lg shadow-lg relative" @click="openModal('https://www.portfolio.clicktostock.com/videos/weather.mp4')">
            <video class="w-full h-full object-cover">
              <source src="https://www.portfolio.clicktostock.com/assets/videos/weather.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 hover:bg-opacity-60 transition">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white" viewBox="0 0 24 24" width="50" height="50">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3l14 9-14 9V3z"/>
              </svg>
            </div>
          </div>
          <h3 class="text-xl font-semibold text-gray-700">Check Weather</h3>
        </div>

        <!-- Video 3 -->
        <div class="flex flex-col items-center">
          <div class="w-full h-64 mb-4 overflow-hidden rounded-lg shadow-lg relative" @click="openModal('https://www.w3schools.com/html/mov_bbb.mp4')">
            <video class="w-full h-full object-cover">
              <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
            <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 hover:bg-opacity-60 transition">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white" viewBox="0 0 24 24" width="50" height="50">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3l14 9-14 9V3z"/>
              </svg>
            </div>
          </div>
          <h3 class="text-xl font-semibold text-gray-700">URL Shortener</h3>
        </div>
      </div>
    </div>

    <!-- Video Modal -->
    <div v-if="isModalVisible" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="relative w-11/12 md:w-3/4 lg:w-1/2">
        <div class="absolute top-0 right-0 p-4" style="z-index:999">
          <button @click="closeModal" class="text-white text-2xl font-bold">&times;</button>
        </div>
        <video ref="modalVideo" class="w-full h-auto" controls autoplay>
          <source :src="videoSrc" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import AppBanner from '@/components/shared/AppBanner';
import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
import Button from '../components/reusable/Button.vue';
export default {
  name: 'Home',
  components: {
    AppBanner,
    ProjectsGrid,
    Button,
  },
  data() {
    return {
      isModalVisible: false,
      videoSrc: '',
    };
  },
  methods: {
    openModal(videoUrl) {
      this.videoSrc = videoUrl;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.$nextTick(() => {
        const videoElement = this.$refs.modalVideo;
        if (videoElement) {
          videoElement.pause();
        }
      });
    },
  },
};
</script>

<style scoped>
/* Style for the mini projects section */
iframe {
  border-radius: 8px;
  transition: transform 0.3s ease;
}

iframe:hover {
  transform: scale(1.05);
}

/* Title for mini projects */
h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #2d3748;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #4a5568;
}
</style>


Ajouter un logigramme selon le niveau du groupe
Générer un emploi du temps

Mélanger les données et vérifier la matière dans un autre groupe de sa filière

Ajouter un semestre dans la matière de la filière

Créer une table pour les notes des travaux, mais pas encore complétée